import type { RawLocation } from '@intlify/vue-router-bridge'
import { storeToRefs } from 'pinia'
import { useGtag } from 'vue-gtag-next'
import type { List, ListItem } from '../types/custom'
import type { Theme } from '~/types/theme'
import { useRoot } from '~/store'
import { useRadar } from '~/store/radar'
import IconTheme from '~/components/icon/Theme.vue'

export const useThemeOptions = () => {
  const localePath = useLocalePath()

  const { t } = useI18n()

  const { theme, themes } = storeToRefs(useRoot())

  const { routeRaw } = storeToRefs(useRadar())

  const { event } = useGtag()

  const themeOptions: ComputedRef<List> = computed(() => {
    return themes.value.map(
      (theme: Theme, id: number): ListItem =>
        ({
          id,
          title: theme.name,
          textContent: t(`themes.${theme.name}`),
          target: localePath({
            path: routeRaw?.value?.path || useRoute().fullPath || '/',
            query: {
              theme: theme.code,
            },
          } as RawLocation),
          iconComponent: markRaw(
            defineComponent({
              render: () => <IconTheme theme={theme} />,
            }),
          ),
        }) as ListItem,
    )
  })

  const themeOption: ComputedRef<ListItem> = computed(
    (): ListItem => ({
      id: themeOptions.value.findIndex((_theme: ListItem) => _theme.textContent === t(`themes.${theme?.value?.name}`)),
      textContent: t(`themes.${theme.value?.name}`),
      target: undefined,
      iconComponent: markRaw(
        defineComponent({
          render: () => <IconTheme theme={theme.value as Theme} />,
        }),
      ),
    }),
  )

  function onThemeSwitch(theme: string) {
    refreshNuxtData()
    event('switch_theme', {
      event_category: 'layout',
      event_label: `theme_${theme?.toLowerCase()}`,
    })
  }

  return {
    themeOptions,
    themeOption,
    onThemeSwitch,
  }
}
